.card__container {
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 5px;
}

.card__container:active {
  box-shadow: none
}

.card {
  position: relative;
  width: 400px;
  height: 266px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.card::before {
  content: '';
  position: absolute;
  background-color: black;
  opacity: .2;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: .2s;
}

.card:hover:before {
  cursor: pointer;
  opacity: .5;
}

.card__title {
  margin: 0;
  padding: 0;
  color: white;
  z-index: 1;
}

.card__info {
  margin: 0;
  padding: 0;
  color: white;
  z-index: 1;
  text-align: center;
}


@media screen and (max-width: 475px) {
  .card {
    width: 350px;
    height: 233px;
  }

  .card__info {
    max-width: 90%;
  }
}

@media screen and (max-width: 400px) {
  .card {
    width: 250px;
    height: 166px;
  }

  .card__title {
    font-size: 16px;
  }

  .card__info {
    font-size: 14px;
  }
}
