.maps__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: inset 0 0 5px;
  height:fit-content;
  padding: 2%;
  margin: 0 2%;
  width: 92%;
}

.yandexMap {
  width: 100%;
  height: 450px;
  border-radius: 5px;
  box-shadow: 1px -1px 16px 2px;
}

.maps__shopList {
  margin: 0;
  padding: 0 0 0 20px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

@media screen and (max-width:900px) {
  .maps__container {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 20px;
  }

  .maps__shopList {
    padding: 0;
  }
}

@media screen and (max-width:475px) {
  .maps__container {
    grid-template-columns: auto;
    grid-template-rows: max-content 1fr;
    row-gap: 20px;
  }

  .yandexMap {
    width: 100%;
    height: 350px;
  }


}

@media screen and (max-width:400px) {
  .yandexMap {
    width: 100%;
    height: 250px;
  }
}
