.header__mainImage {
  max-width: 100%;
}


.main__productsBlock {
  background-image: url('../../images/header__background.png');
  background-color: white;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #666666;
  padding: 0 0 50px;
}

.productsBlock__title {
  margin: 0 0 20px;
  padding: 50px 0 0;
}

.productsBlock__subtitle {
  margin: 0 0 25px;
  padding: 0;
  font-size: 18px;
}

.productsBlock__container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-template-rows: minmax(266px, 1fr);
  gap: 25px;
}

.main__aboutUs {
  background-color: #f7f7f7;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0 25px;
}

.aboutUs__title {
  font-size: 32px;
  margin: 0 0 25px;
  padding: 25px 0 0;
  align-self: center;
}

.title__general {
  margin: 0 2% 25px;
  padding: 0;
}

.aboutUs__titles {
  margin: 0 0 25px;
  padding: 0;
  align-self: center;
}

.aboutUs__list {
  margin: 0 0 25px;
}

.aboutUs__list:last-child {
  margin: 0;
}

.main__contacts {
  background-image: url('../../images/header__background.png');
  background-color: white;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #666666;
  padding: 0 0 50px;
}

.contacts__title {
  margin: 0 0 20px;
  padding: 20px 0 0;
  font-size: 32px;
}

@media screen and (max-width: 475px) {
  .productsBlock__container {
    grid-template-columns: repeat(auto-fill, 350px);
    grid-template-rows: minmax(233px, 1fr);
  }
}

@media screen and (max-width: 400px) {
  .productsBlock__container {
    grid-template-columns: repeat(auto-fill, 250px);
    grid-template-rows: minmax(166px, 1fr);
  }

  .aboutUs__title {
    margin-bottom: 15px;
  }

  .aboutUs__titles {
    margin-bottom: 15px;
  }

  .title__general {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .aboutUs__list {
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 400px) {

  .title__general {
    font-size: 10px;
    max-width: 80%;
    align-self: center;
  }

  .aboutUs__list {
    font-size: 10px;
    max-width: 80%;
  }
}
