.notFound__mainText {
  max-width: 100%;
  height: 96px;
  margin: 0;
  padding: 0;
  color: white;
  background-color: #F49A11;
  display: flex;
}

.text__section {
  margin: 0;
  padding: 0 0 0 5%;
  width: 50%;
  font-size: 75px;
  font-weight: 700;
}

.notFound__content {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.notFound__image {
  margin-top: -120px;
  padding-left: 5%;
  min-width: 100px;
}

.notFound__textBlock {
  padding-top: 150px;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notFound__description {
  max-width: 100%;
  color: #666666;
  font-size: 50px;
  text-align: center;
}

.notFound__backButton {
  margin: 0;
  padding: 0;
  border: none;
  width: fit-content;
  background-color: inherit;
  font-size: 40px;
  color: #888;
  transition: .2s;
  cursor: pointer;
}

.notFound__backButton:hover {
  opacity: .8;
}

@media screen and (max-width:1279px) {
  .notFound__image {
    padding-left: 0;
  }

  .notFound__textBlock {
    padding-top: 50px;
  }

  .notFound__description {
    font-size: 40px;
  }

  .notFound__backButton {
    font-size: 30px;
  }
}

@media screen and (max-width: 948px) {
  .notFound__content {
    flex-direction: column;
  }

  .notFound__image {
    margin-top: 0;

  }

  .notFound__mainText {
    text-align: center;
    height: max-content;
    margin-bottom: 20px;
  }

  .text__section {
    padding: 0;
  }

  .notFound__textBlock {
    padding: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 475px) {
  .text__section {
    font-size: 40px;
  }

  .notFound__description {
    font-size: 30px;
  }

  .notFound__backButton {
    font-size: 20px;
  }
}

@media screen and (max-width: 350px) {
  .notFound__description {
    font-size: 20px;
  }

  .notFound__backButton {
    font-size: 15px;
  }
}
