.header__navigation {
  width: 100%;
  background-color: #f7f7f9;
  padding: 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 2%;
  padding: 0;
  list-style-type: none;
}

.nav__logo_type_burger {
  margin: 0 0 30px;
}

.header__links {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;

}

.header__links_type_active {
  flex-direction: column;
  align-items: flex-start;
}

.header__links_type_active>.header__links_item {
  margin: 0 0 20px;
}

.header__links_type_active>.contacts {
  padding: 30px 0 0;
}


.header__links_item {
  margin-right: 20px;
}

.header__links_item:last-of-type {
  margin-right: 0;
}

.nav__links {
  flex-grow: 1;
}

.header__link {
  text-decoration: none;
  color: #888;
  transition: .2s;
  font-size: 30px;
}

.header__link:hover {
  color: #666666;
}

.nav__picture {
  margin: 0;
  max-width: 300px;
}

.nav__logoBurger {
  margin: 0 0 30px;
  max-width: 300px;
}

.nav__burger-menu {
  display: none;
  z-index: 10;
}

.nav__burger-label {
  cursor: pointer;
  height: 44px;
  width: 44px;
  background-image: url('../../images/burger-menu.svg');
  background-position: center;
  background-repeat: no-repeat;
  transition: .2s;
  z-index: 10;
}

.burger__menu {
  display: flex;
  flex-direction: column;
}

.nav__burger-label:hover {
  opacity: .8;
}

.nav__burger-label_type_open {
  background-image: url('../../images/burger__close.svg');
}

.navigation__bar {
  background-color: #fff;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  transition: visibility .3s, opacity .3s;
  z-index: 1;
  padding-top: 100px;
}

.navigation__bar:before {
  display: block;
  content: '';
  background-color: rgba(0, 0, 0, .5);
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  position: fixed;
}

.user__navigation_type_active {
  display: flex;
  margin: 0 0 28px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

@media screen and (max-width: 1025px) {
  .nav__picture {
    max-width: 200px;
  }
}

@media screen and (max-width:550px) {
  .nav__picture {
    max-width: 170px;
  }
}


@media screen and (max-width:475px) {
  .nav__panel {
    display: grid;
    grid-template-areas:
      "logo burger"
      "contacts .";
  }

  .header__logo {
    grid-area: logo;
  }

  .burger__menu {
    grid-area: burger;
  }

  .navigation__bar {
    width: 100%;
  }

  .navigation__bar:before {
    width: 0;
  }
}
