.shopList__shop {
  background-color: white;
  padding: 10px;
  box-shadow: 0 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: .2s;
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;
}

.shopList__shop_isActive {
  background-color: #666;
}

.shopList__shop:hover {
  background-color: #f7f7f7;
  box-shadow: inset 0 0 5px;
}

.shop__title {
  margin: 0;
  padding: 0;
}

.shop_address {
  margin: 0;
  padding: 0;
}

.shop__contact {
  text-decoration: none;
  color: #666;
  width: fit-content;
}

@media screen and (max-width:475px) {
  .shop__title {
    font-size: 14px;
  }

  .shop_address {
    font-size: 14px;
  }

  .shop__contact {
    font-size: 14px;
  }
}
