#root {
  min-width: 320px;
  max-width: 1440px;
  min-height: 100vh;
  background-color: white;
  box-sizing: border-box;
  margin: 0 auto;
}

.page__container {
  max-width: 100%;
  font-family: 'M.Video', Arial, sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  position: relative;
}
